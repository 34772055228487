<template>
  <div class="header">
    <el-button style="margin-right:20px ;" @click="$router.push('/')">回到首页</el-button>
    <el-select
      filterable
      remote
      multiple
      size="large"
      class="input"
      ref="searchInput"
      placeholder="请输入关键词"
      loading-text="检索中..."
      no-data-text="未找到"
      :remote-method="search"
      :loading="searchLoading"
    >
      <el-option
        v-for="item in searchResult"
        :key="item.id"
        @click="goDetail(item)"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <el-button @click="goSearchPage">文库检索</el-button>
  </div>
</template>
<script setup>
import { ref } from "vue";
import {
  getCategory as _getCategory,
  getVideoList as _getVideoList,
} from "@api";
import { useRouter } from "vue-router";
const router = useRouter()
const keywords = ref("");
const searchLoading = ref(false);
const searchResult = ref([]);
const search = (key) => {
  keywords.value = key;
  if (key) {
    searchLoading.value = true;
    _getVideoList({
      key,
      type: 2,
    })
      .then((res) => {
        searchResult.value = res.data.records;
      })
      .finally(() => {
        searchLoading.value = false;
      });
  } else {
    searchResult.value = [];
  }
};
const goDetail = (r) => {
  router.push('/documentPreview/' + r.id)
}
const goSearchPage = () => {
  if (keywords.value.trim()) {
    router.push('/documentSearch/' + keywords.value.trim())
  }
}
</script>
<style scoped lang="stylus">
.header {
  display: flex;
  justify-content: center;
  position relative

  .back-home {
    position absolute
  }
  .input {
    width: 60%;
    margin-right: 20px;
  }
  background-color #5797ff
  padding 30px 10px
}
</style>
