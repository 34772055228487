<template>
  <div>
    <div v-if="!route.params.keyword" class="menu">
      <div class="list-wrapper">
        <span
          class="list-item"
          :class="{ active: l.id === activeFirstType }"
          v-for="l in typeList"
          :key="l.id"
          @click="changeType(l.id)"
          >{{ l.name }}</span
        >
      </div>
      <div class="list-wrapper" v-if="subList.length">
        <span
          class="list-item-sub"
          @click="changeSecondType(l.id)"
          v-for="l in subList"
          :class="{ active: l.id === activeSecondType }"
          :key="l.id"
          >{{ l.name }}</span
        >
      </div>
    </div>
    <div class="tip" v-else>
      搜索<b>“{{route.params.keyword}}”</b>的全部结果
    </div>
    <div class="doc-wrapper">
      <div v-for="r in resList" @click="goPreview(r)" class="doc-item">
        <div
          :style="{ backgroundImage: `url(${r.coverImgUrl})` }"
          class="cover"
          alt=""
        ></div>
        <div class="item-name">
          <span class="icon"></span>
          <div class="title">{{ r.name }}</div>
        </div>
        <div class="date">{{ r.uploadTime }}</div>
      </div>
    </div>
    <div class="doc" style="text-align:center; margin-top: 10px;padding-bottom: 20px;">
      <Pagenation
        @change="pageChange"
        :pageSize="20"
        :currentPage="cp"
        :total="total"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watchEffect } from "vue";
import { getCategory, getVideoPage, getVideoList } from "@api";
import { useRouter, useRoute } from "vue-router";
import Pagenation from "@c/Pagenation.vue";

const router = useRouter();
const route = useRoute();
const typeList = ref([]);
const menuMap = ref({});
// const activeFirstType = ref();
// const activeSecondType = ref(Number(route.params.sid) || 0);
const currentPage = ref(0);
const total = ref(0);
const resList = ref([]);

const activeFirstType = computed(() => {
  return Number(route.params.fid) || (typeList.value.length ? typeList.value[0].id : 0)
})

const activeSecondType = computed(() => {
  return Number(route.params.sid) || (subList.value.length ? subList.value[0].id : 0)
})

const pageChange = (pageNo) => {
  currentPage.value = pageNo - 1;
  const key = route.params.keyword;
  const params = {
    currentPage: currentPage.value,
    pageSize: 20,
    type: 2
  }
  let api = getVideoList
  if (key) {
    params.key = key
  } else {
    params.categoryId = categoryId.value
    api = getVideoPage
  }
  api(params).then((res) => {
    total.value = res.data.total;
    resList.value = res.data.records;
    total.value = res.data.total;
  });
};


const subList = computed(() => {
  return (
    activeFirstType.value && Object.keys(menuMap.value).length &&
    (menuMap.value[activeFirstType.value].children || [])
  ) || [];
});

const changeType = (id) => {
  router.push("/document/" + id);
};

const changeSecondType = (id) => {
  router.push("/document/" + activeFirstType.value + '/' + id);
};

const cp = computed(() => currentPage.value + 1);

const getCategoryList = () => {
  getCategory({
    type: 2,
  }).then((res) => {
    const menus = [];
    const idMap = {};
    res.data
      .sort((a, b) => a.rank - b.rank)
      .forEach((i) => {
        if (i.parentId === null) {
          menus.push(i);
        } else if (idMap[i.parentId]) {
          idMap[i.parentId].children = idMap[i.parentId].children || [];
          idMap[i.parentId].children.push(i);
        }
        idMap[i.id] = i;
      });
    typeList.value = menus;
    menuMap.value = idMap;
  });
};

const categoryId = computed(
  () => {
    return activeSecondType.value || activeFirstType.value
  }
);

watchEffect(() => {
  if (!route.params.keyword) {
    getVideoPage({
      categoryId: categoryId.value,
      currentPage: 0,
      pageSize: 20,
      type: 2,
    }).then((res) => {
      
      total.value = res.data.total;
      resList.value = res.data.records;
      total.value = res.data.total;
    });
  } else {
    pageChange(1);
  }
});

// watchEffect(() => {
//   activeSecondType.value = subList.value.length ? subList.value[0].id : 0;
// });

const goPreview = (r) => {
  router.push("/documentPreview/" + r.id);
};

getCategoryList();
</script>
<style scoped lang="stylus">
.tip {
  height 60px
  line-height 60px
  padding-left 20px
  font-size 20px
}
.doc-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  align-items: center;
  justify-items: center;
  padding: 10px;
  box-sizing: border-box;
}
@media screen and (max-width: 770px) {
  .doc-wrapper {
    grid-template-columns: repeat(2, 50%);
  
  }
}
.doc-item {
  width: 70%;
  background-color: #fff;
  padding: 10px;
  border-radius: 2px;
  cursor pointer;
  margin-bottom: 20px;

}
.item-name {
  display: flex;
  align-items center
  height 45px
}
.icon {
  background-image: url("~@/assets/pic/pdf.png");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}
.cover {
  width: 100%;
  padding-bottom 120%;
  background-color #f6f6f6;
  border-radius 6px;
  margin-bottom 10px;
  height: 0;
  background-size cover
  background-repeat no-repeat
  background-position center
}
.title {
  margin-left 10px
  max-height: 45px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.date {
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  margin-top: 10px;
  border-top: 1px solid #eee;
  color: #666;
}
.list-wrapper {
  height: 40px;
  line-height: 40px;
  .list-item,.list-item-sub {
    height 30px;
    line-height 30px;
    box-sizing border-box
    padding: 0px 20px;
    background-color #fff
    color #666
    display inline-block
    cursor pointer
    &+.list-item, &+.list-item-sub {
      margin-left 10px
    }
    &.active {
      background-color #5797ff
      border-radius 4px
      color #fff
    }
  }
  .list-item-sub.list-item-sub {
    background-color transparent
    border-radius 4px
    height 15px
    line-height 15px
    &.active {
      color #5797ff
    }
  }
}
.menu {
  padding 20px
  padding-bottom 10px
  border-bottom 1px solid #ccc;
  margin-bottom 10px
}
.doc ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active) {
  background-color: #5797ff;
}
</style>
